@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  background-color: #f9f7f7;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.calendar-details{
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: env(safe-area-inset-bottom, 1rem);
}

.footer-wrapperstyles__FooterWrapper-sc-12dpj1x-0.irWqeX {
  top: 0px;
}



button.ButtonWrapper-sc-__sc-1qu8p4z-0.bueyKc {
  display: none;
}


button.ButtonWrapper-sc-__sc-1qu8p4z-0.bDKJXU {
  transform: rotate(270deg);
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 2px black;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px black;
  }
}